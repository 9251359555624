import consumer from "./consumer";

// Keep track of our subscription globally
let currentSubscription = null;
let processedMessageIds = new Set();

// Create a function to handle the cleanup of existing subscriptions
const cleanupExistingSubscription = () => {
   if (currentSubscription) {
       console.log("Cleaning up existing subscription");
       consumer.subscriptions.remove(currentSubscription);
       currentSubscription = null;
   }
   // Clear processed message IDs when cleaning up
   processedMessageIds.clear();
};

// Initialize on page load
document.addEventListener('turbolinks:load', () => {
   console.log("Initializing message channel");
   
   // Clean up any existing subscription
   cleanupExistingSubscription();

   const messageForm = document.getElementById("message-form");
   console.log("Message form found:", messageForm);
   
   if (messageForm) {
        // Remove data-remote attribute to prevent Rails UJS from handling the form
        messageForm.removeAttribute('data-remote');
        
        messageForm.addEventListener('submit', function(event) {
            event.preventDefault();
            
            const messageBody = messageForm.querySelector('#message_body');
            if (!messageBody || !messageBody.value.trim()) {
                return;
            }

            const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
            const formData = new FormData(messageForm);

            const submitButton = messageForm.querySelector('input[type="submit"]');
            if (submitButton) {
                submitButton.disabled = true;
            }
            
            fetch(messageForm.action, {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRF-Token': csrfToken,
                    'Accept': 'application/json'
                },
                credentials: 'same-origin'
            })
            .then(response => {
                if (response.ok) {
                    messageBody.value = '';
                    messageBody.style.height = 'auto';
                } else {
                    console.error('Response not ok:', response);
                }
            })
            .finally(() => {
                if (submitButton) {
                    submitButton.disabled = false;
                }
            })
            .catch(error => console.error('Error:', error));
        });
    }
 
   const conversationElement = document.getElementById('messages-container');
   
   if (conversationElement) {
        const conversationId = conversationElement.dataset.conversationId;
        console.log("Setting up subscription for conversation:", conversationId);

        // Initialize the Set with existing message IDs
        const existingMessages = conversationElement.querySelectorAll('[data-message-id]');
        existingMessages.forEach(message => {
            processedMessageIds.add(message.dataset.messageId);
        });

        currentSubscription = consumer.subscriptions.create(
            { channel: "MessageChannel", conversation_id: conversationId },
            {
                connected() {
                    console.log("Connected to MessageChannel for conversation:", conversationId);
                },
                disconnected() {
                    console.log("Disconnected from MessageChannel for conversation:", conversationId);
                },
                received(data) {
                    console.log("Received message with ID:", data.message_id);
                    
                    // Check if we've already processed this message
                    if (processedMessageIds.has(data.message_id.toString())) {
                        console.log("Message already processed, skipping:", data.message_id);
                        return;
                    }

                    const messagesContainer = document.getElementById('messages-container');
                    if (messagesContainer) {
                        // Add message to processed set
                        processedMessageIds.add(data.message_id.toString());
                        
                        messagesContainer.insertAdjacentHTML('beforeend', data.message);
                        messagesContainer.scrollTop = messagesContainer.scrollHeight;

                        const currentUserId = Number(document.body.dataset.currentUserId);
                        if (data.sender_id !== currentUserId) {
                            updateMessagesDropdown();
                        }
                    }
                }
            }
        );
    }

   // Handle Mark All as Read button
   document.addEventListener('click', function(event) {
        const markAllButton = event.target.closest('[data-action="mark-all-as-read"]');
        if (markAllButton) {
            event.preventDefault();
            const form = markAllButton.closest('form');
            
            fetch(form.action, {
                method: 'POST',
                headers: {
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'same-origin'
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const messagesContent = document.querySelector('.messages-content');
                    if (messagesContent) {
                        // Update the header
                        const header = messagesContent.querySelector('.d-flex.justify-content-between');
                        if (header) {
                            header.innerHTML = '<h5 class="me-sm-4">Messages</h5>';
                        }

                        // Remove all message boxes
                        const messageBoxes = messagesContent.querySelectorAll('.single-box');
                        messageBoxes.forEach(box => {
                            box.remove();
                        });

                        // Add the "No new messages" indicator
                        const btnArea = messagesContent.querySelector('.btn-area');
                        if (btnArea) {
                            const noMessagesDiv = document.createElement('div');
                            noMessagesDiv.className = 'text-center mb-7';
                            noMessagesDiv.innerHTML = '<p class="mdtxt text-muted">No new messages</p>';
                            btnArea.insertAdjacentElement('beforebegin', noMessagesDiv);
                        }

                        // Hide the unread count badge
                        const unreadBadge = document.querySelector('.messages-btn .abs-area');
                        if (unreadBadge) {
                            unreadBadge.style.display = 'none';
                        }
                    }
                }
            })
            .catch(error => console.error('Error marking messages as read:', error));
        }
    });
});

// Function to update messages dropdown
const updateMessagesDropdown = () => {
    fetch('/messages/dropdown_content', {
        headers: {
            'Accept': 'text/html',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
    .then(response => response.text())
    .then(html => {
        const messagesArea = document.querySelector('.messages-area');
        if (messagesArea) {
            const temp = document.createElement('div');
            temp.innerHTML = html;
            const newMessagesArea = temp.querySelector('.messages-area');
            if (newMessagesArea) {
                messagesArea.replaceWith(newMessagesArea);
            }
        }
    })
    .catch(error => console.error('Error updating messages dropdown:', error));
};

// Clean up on page change
document.addEventListener('turbolinks:before-visit', cleanupExistingSubscription);
document.addEventListener('turbolinks:before-cache', cleanupExistingSubscription);