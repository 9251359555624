import $ from 'jquery'
window.jQuery = window.$ = $

import 'bootstrap'
import '../../assets/stylesheets/application.scss';
import Rails from "@rails/ujs";
Rails.start();

import { createConsumer } from "@rails/actioncable"
window.createConsumer = createConsumer
import "../channels"

import Turbolinks from 'turbolinks';
Turbolinks.start();

// Import Slick
import 'slick-carousel'

// Import all functionalities
import { setupLikeFunctionality } from '../javascripts/like_functionality';
import { setupCommentFunctionality } from '../javascripts/comment_functionality';
import { setupNotificationFunctionality } from '../javascripts/notification_functionality';
import { setupMessageDropdownFunctionality } from '../javascripts/message_dropdown_functionality';
import { setupStoriesFunctionality } from '../javascripts/stories';
import { setupDistanceFilter } from '../javascripts/distance_filter.jsx';
import { setupShareFunctionality } from '../javascripts/share_functionality';
import { setupPostFunctionality } from '../javascripts/post_functionality';

// Make sure all functions are attached to window
window.setupLikeFunctionality = setupLikeFunctionality;
window.setupCommentFunctionality = setupCommentFunctionality;
window.setupNotificationFunctionality = setupNotificationFunctionality;
window.setupMessageDropdownFunctionality = setupMessageDropdownFunctionality;
window.setupStoriesFunctionality = setupStoriesFunctionality;
window.setupDistanceFilter = setupDistanceFilter;
window.setupShareFunctionality = setupShareFunctionality;
window.setupPostFunctionality = setupPostFunctionality;

// Add debug logging
console.log('Checking window functions after assignment:');
console.log('setupShareFunctionality:', typeof window.setupShareFunctionality);
console.log('setupLikeFunctionality:', typeof window.setupLikeFunctionality);

document.addEventListener("turbolinks:load", () => {
  console.log("turbolinks:load event fired");
  console.log("Checking setupShareFunctionality availability:", typeof window.setupShareFunctionality);
  
  // Initialize all functionalities
  setupLikeFunctionality();
  setupCommentFunctionality();
  setupNotificationFunctionality();
  setupMessageDropdownFunctionality();
  setupStoriesFunctionality();
  setupDistanceFilter();
  setupShareFunctionality();
  setupPostFunctionality();
});

// This will import all files from the javascripts directory
const context = require.context('../javascripts', true, /\.js$/);
context.keys().forEach(context);

console.log('application.js loaded');