export const setupPostFunctionality = () => {
  console.log('Initializing post functionality');
  
  let selectedFiles = [];
  const maxFiles = 10;
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  
  // Sync textareas between main post area and modal
  $('#post-text').on('input', function() {
    $('#modal-post-text').val($(this).val());
    updatePostButtonState();
  });

  $('#modal-post-text').on('input', function() {
    $('#post-text').val($(this).val());
    updatePostButtonState();
  });

  // Handle file selection
  $('#file-input').on('change', function(e) {
    console.log('File input change event triggered');
    const files = Array.from(e.target.files);
    handleFiles(files);
  });

  // Handle drag and drop
  $('.file-custom').on('dragover', function(e) {
    e.preventDefault();
    $(this).addClass('dragover');
  }).on('dragleave drop', function(e) {
    e.preventDefault();
    $(this).removeClass('dragover');
    
    if (e.type === 'drop') {
      const files = Array.from(e.originalEvent.dataTransfer.files);
      handleFiles(files);
    }
  });

  function handleFiles(files) {
    console.log('Handling files:', files);
    const validFiles = files.filter(file => 
      file.type.startsWith('image/') || file.type.startsWith('video/')
    );

    console.log('Valid files:', validFiles);

    if (selectedFiles.length + validFiles.length > maxFiles) {
      showError(`You can only upload up to ${maxFiles} files`);
      return;
    }

    selectedFiles = [...selectedFiles, ...validFiles];
    updatePreviews();
    updatePostButtonState();
  }

  function updatePostButtonState() {
      if ($('#modal-post-text').length) {  // Only run if element exists
          const postText = $('#modal-post-text').val();
          const hasContent = postText.trim().length > 0 || selectedFiles.length > 0;
          $('#post-button').prop('disabled', !hasContent);
      }
  }

  function updatePreviews() {
    const $previewArea = $('#preview-area');
    const $container = $previewArea.find('.preview-container');
    $container.empty();

    if (selectedFiles.length > 0) {
      $previewArea.removeClass('d-none');
      
      selectedFiles.forEach((file, index) => {
        const $preview = $('<div class="col-6 col-sm-4 position-relative">');
        const $removeBtn = $(`
          <button class="position-absolute top-0 end-0 btn btn-sm btn-light rounded-circle m-1" data-index="${index}">
            <i class="material-symbols-outlined mat-icon">close</i>
          </button>
        `);

        if (file.type.startsWith('image/')) {
          const imgUrl = URL.createObjectURL(file);
          $preview.append(`<img src="${imgUrl}" class="img-fluid rounded">`);
        } else if (file.type.startsWith('video/')) {
          const videoUrl = URL.createObjectURL(file);
          $preview.append(`<video src="${videoUrl}" class="img-fluid rounded"></video>`);
        }

        $preview.append($removeBtn);
        $container.append($preview);
      });
    } else {
      $previewArea.addClass('d-none');
    }
  }

  // Handle file upload through UploadsController
  async function uploadFile(file, listingId) {
    console.log('Starting file upload for:', file.name, 'to listing:', listingId);
    
    const formData = new FormData();
    formData.append('image_file', file);
    formData.append('image_type', 'listing');
    formData.append('image_size', 'original');
    formData.append('record_id', listingId);
    formData.append('from_modal', 'true');
  
    console.log('FormData contents:');
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
  
    try {
      console.log('Sending request to /uploads/upload_image');
      const response = await fetch('/uploads/upload_image', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json'
        },
        body: formData
      });
      
      console.log('Response status:', response.status);
      const result = await response.json();
      console.log('Response data:', result);
  
      if (!result.success) {
        throw new Error(result.message);
      }
      
      return result.filename;
    } catch (error) {
      console.error('Upload error:', error);
      throw new Error(`Failed to upload file: ${error.message}`);
    }
  }

  async function uploadVideo(file, listingId) {
    console.log('Starting video upload for:', file.name, 'to listing:', listingId);
    
    const formData = new FormData();
    formData.append('video[title]', file.name);  // Use filename as title
    formData.append('video[description]', '');    // Empty description for modal uploads
    formData.append('video[video_type]', 'native');
    formData.append('video[file]', file);
    formData.append('listing_id', listingId);
  
    try {
      // Update upload status in UI
      const $previewArea = $('#preview-area');
      const $progressContainer = $('<div class="progress mt-2">');
      const $progressBar = $('<div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">');
      const $uploadStatus = $('<div class="upload-status mt-1">');
      
      $progressContainer.append($progressBar);
      $previewArea.append($progressContainer);
      $previewArea.append($uploadStatus);
  
      const response = await new Promise((resolve, reject) => {
        $.ajax({
          url: '/upload_native_video',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          headers: {
            'X-CSRF-Token': csrfToken
          },
          xhr: function() {
            const xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener("progress", function(evt) {
              if (evt.lengthComputable) {
                const percentComplete = evt.loaded / evt.total * 100;
                $progressBar.css('width', percentComplete + '%');
                $progressBar.attr('aria-valuenow', percentComplete);
                $uploadStatus.text('Uploading: ' + Math.round(percentComplete) + '%');
              }
            }, false);
            return xhr;
          },
          success: function(response) {
            resolve(response);
          },
          error: function(xhr, status, error) {
            let errorMessage = 'Upload failed: ';
            try {
              const response = JSON.parse(xhr.responseText);
              errorMessage += response.message || error;
              if (response.errors) {
                errorMessage += '\n' + response.errors.join('\n');
              }
            } catch(e) {
              errorMessage += error;
            }
            reject(new Error(errorMessage));
          }
        });
      });
  
      if (response.status === 'success') {
        $uploadStatus.text('Upload completed successfully!');
        
        // Clean up UI after success
        setTimeout(() => {
          $progressContainer.remove();
          $uploadStatus.remove();
        }, 2000);
  
        return response;
      } else {
        throw new Error(response.message);
      }
  
    } catch (error) {
      console.error('Video upload error:', error);
      throw new Error(`Failed to upload video: ${error.message}`);
    }
  }

  // Remove file from preview
  $(document).on('click', '.preview-container button', function() {
    const index = $(this).data('index');
    selectedFiles = selectedFiles.filter((_, i) => i !== index);
    updatePreviews();
    updatePostButtonState();
  });

  // Handle post submission
  $('#post-button').on('click', async function() {
    console.log('Post button clicked');
    const $button = $(this);
    const $spinner = $button.find('.spinner-border');
    const $buttonText = $button.find('.button-text');
    const postText = $('#modal-post-text').val();

    if (!postText.trim() && selectedFiles.length === 0) {
      showError('Please add some text or media to your post');
      return;
    }

    // Disable button and show spinner
    $button.prop('disabled', true);
    $buttonText.addClass('d-none');
    $spinner.removeClass('d-none');

    try {
      // First create the listing/post
      console.log('Creating initial listing');
      const initialFormData = new FormData();
      initialFormData.append('listing[description]', postText);
      initialFormData.append('listing[post_type]', 'post');
      initialFormData.append('listing[title]', postText.slice(0, 50));

      const listingResponse = await fetch('/listings', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'application/json'
        },
        body: initialFormData
      });

      if (!listingResponse.ok) {
        const data = await listingResponse.json();
        throw new Error(data.errors?.join(', ') || 'Failed to create post');
      }

      const listingData = await listingResponse.json();
      console.log('Listing created:', listingData);
      const listingId = listingData.listing.id;

      // Then upload all files
      console.log('Uploading files for listing:', listingId);
      const uploadPromises = selectedFiles.map(async file => {
        if (file.type.startsWith('image/')) {
          return uploadFile(file, listingId);
        } else if (file.type.startsWith('video/')) {
          return uploadVideo(file, listingId);
        }
      });

      const uploadResults = await Promise.all(uploadPromises);
      console.log('All files uploaded:', uploadResults);

      // Reset form
      $('#modal-post-text, #post-text').val('');
      selectedFiles = [];
      updatePreviews();
      
      // Close modal and refresh feed
      $('#photoVideoMod').modal('hide');
      Turbolinks.visit(window.location.href);
    } catch (error) {
      console.error('Error during post creation:', error);
      showError(error.message);
    } finally {
      // Re-enable button and hide spinner
      $button.prop('disabled', false);
      $buttonText.removeClass('d-none');
      $spinner.addClass('d-none');
    }
  });

  function showError(message) {
    console.error('Error:', message);
    const $errorDiv = $('#error-message');
    $errorDiv.text(message).removeClass('d-none');
    setTimeout(() => $errorDiv.addClass('d-none'), 5000);
  }

  // Clear files when modal is closed
  $('#photoVideoMod').on('hidden.bs.modal', function() {
    console.log('Modal hidden - clearing state');
    $('#file-input').val('');
    $('#modal-post-text, #post-text').val('');
    selectedFiles = [];
    updatePreviews();
    updatePostButtonState();
  });

  // Initialize post button state
  updatePostButtonState();

  console.log('Post functionality initialized');
};

export default setupPostFunctionality;