document.addEventListener('turbolinks:load', function() {
    $(document).on('click', '.follow-btn', function(e) {
        e.preventDefault();
        var $button = $(this);
        var $form = $button.closest('form');
        var currentText = $button.find('.btn-text').text().trim();
        var isFollowing = currentText === 'Follow';
        
        $.ajax({
            url: $form.attr('action'),
            method: $form.attr('method'),
            dataType: 'json',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                if (response.success) {
                    // Create the new form HTML with opposite state and icon
                    var newHtml = `
                        <form class="follow-form" method="${isFollowing ? 'delete' : 'post'}" 
                              action="${isFollowing ? response.unfollow_path : response.follow_path}" 
                              data-remote="true">
                            <button class="btn btn-primary d-center gap-1 follow-btn" type="submit">
                                <i class="material-symbols-outlined mat-icon fs-4">${isFollowing ? 'person_remove' : 'person_add'}</i>
                                <span class="btn-text">${isFollowing ? 'Unfollow' : 'Follow'}</span>
                            </button>
                            <input type="hidden" name="authenticity_token" 
                                   value="${$('meta[name="csrf-token"]').attr('content')}" 
                                   autocomplete="off">
                        </form>`;
                    
                    // Replace the old form with the new one
                    $form.replaceWith(newHtml);
                    
                    // Update followers count if it exists
                    var userId = $button.data('user-id');
                    if (userId) {
                        $('.followers-count-' + userId).text(response.followers_count);
                    }
                }
            },
            error: function(xhr, status, error) {
                console.error('Error:', error);
                console.error('Response:', xhr.responseText);
                alert('An error occurred. Please try again.');
            }
        });
    });
});